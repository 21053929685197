<template>
  <div class="flex justify-center !my-4 modal-slide-in">
    <form
      @submit.prevent=""
      @keydown.enter.prevent=""
      ref="form"
      class="bg-white form shadow border min-h-[300px] justify-between rounded flex flex-col"
    >
      <h1 class="heading1 k1:!text-3xl !text-2xl mb-auto">
        Request a DigiQ-Track Account
        <hr class="gradientLine" />
      </h1>
      <div class="select-none">
        <StatusMessage operation="REQUEST_DIGIQT_ACCOUNT"></StatusMessage>
      </div>
      <StatusMessage operation="LIST_ASSETS"></StatusMessage>

      <div class="flex flex-col justify-center align-center">
        <div v-if="!isSubmitted && !loadingRequest" class="flex flex-col">
          <p class="py-2 text-gray-600">
            Create a DigiQ-Track account by filling in below details. You will
            receive an activation email, which may be in your spam folder.
          </p>

          <div class="w-full mb-2">
            <label for="firstName">First Name*</label>
            <TextInput
              autofocus
              id="firstName"
              name="firstName"
              placeholder="First Name"
              :value="account.firstName"
              @change="(value) => updateAccount('firstName', value, true)"
              @input="(value) => updateAccount('firstName', value, true)"
            ></TextInput>
            <LFieldError :message="inputError.firstName" class="w-full">
            </LFieldError>
          </div>
          <div class="w-full mb-2">
            <label for="lastName">Last Name*</label>
            <TextInput
              autofocus
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              :value="account.lastName"
              @change="(value) => updateAccount('lastName', value, true)"
              @input="(value) => updateAccount('lastName', value, true)"
            ></TextInput>
            <LFieldError :message="inputError.lastName" class="w-full">
            </LFieldError>
          </div>
          <div class="w-full mb-2">
            <label for="title">Academic Title</label>
            <TextInput
              autofocus
              id="title"
              name="title"
              placeholder="Your Academic Title"
              :value="account.title"
              @change="(value) => updateAccount('title', value)"
            ></TextInput>
            <LFieldError :message="inputError.name" class="w-full">
            </LFieldError>
          </div>
          <div class="flex justify-between gap-4 k1:flex-row flex-col mb-2">
            <div class="flex flex-col">
              <label for="university">Institution*</label>
              <DropdownSingle
                :title="account.university || 'Select University'"
                :items="availableUnis"
                class="text-sm"
                :tabindex="3"
                id="university"
                name="university"
                placeholder="Select University"
                @select="(value) => updateAccount('university', value, true)"
              />
              <LFieldError :message="inputError.university" class="w-full">
              </LFieldError>
              <div
                v-if="account.university === 'Not Listed'"
                class="text-sm self-center italic"
              >
                If your university does not appear, please contact
                <a class="text-blue-700" href="mailto:digiqtrack@gmail.com"
                  >digiqtrack@gmail.com</a
                >.
              </div>
            </div>
            <div v-if="!role" class="flex flex-col">
              <label for="role">Role*</label>
              <DropdownSingle
                class="text-sm"
                :title="account.role || 'Select Your Role'"
                :items="roles"
                :tabIndex="5"
                id="role"
                name="role"
                placeholder="Select Your Role"
                @select="(value) => updateAccount('role', value, true)"
              />
              <LFieldError :message="inputError.role" class="w-full">
              </LFieldError>
            </div>
          </div>
          <div v-if="showStudentFields" class="w-full mb-2">
            <label for="programme">Study Programme*</label>
            <TextInput
              autofocus
              id="programme"
              name="programme"
              placeholder="Programme degree, name, and year"
              @change="(value) => updateAccount('programme', value, true)"
              @input="(value) => updateAccount('programme', value, true)"
            ></TextInput>
            <LFieldError :message="inputError.programme" class="w-full">
            </LFieldError>
          </div>
          <div class="mb-2">
            <label for="email">Email address*</label>
            <TextInput
              id="email"
              name="email"
              :value="account.email"
              placeholder="Email address"
              @input="(value) => updateAccount('email', value, true)"
              @change="(value) => updateAccount('email', value, true)"
            ></TextInput>
            <LFieldError :message="inputError.email"></LFieldError>
          </div>
          <div class="mb-2 text-sm">Fields with * are required</div>
        </div>
        <div
          v-if="loadingRequest"
          class="flex h-full items-center justify-center gap-2"
        >
          <p class="text-center">Loading...</p>
        </div>
        <div
          v-if="isSubmitted && !loadingRequest"
          class="items-center justify-center w-full flex flex-row"
        >
          <DigiQButton
            type="submit"
            :tabindex="13"
            :is-primary="true"
            @click="$router.push('/track')"
          >
            <template #right>Go to Home</template>
          </DigiQButton>
        </div>
        <div
          v-if="!isSubmitted && !loadingRequest"
          class="items-center gap-4 justify-end flex"
        >
          <DigiQButton @click="$router.go(-1)" isSecondary="true">
            <template #center> Back</template>
          </DigiQButton>
          <DigiQButton
            :class="
              canSubmit && !isSubmitted ? '' : 'opacity-50 pointer-events-none'
            "
            v-if="!isLoggedIn"
            @click="onSubmit"
            @keydown.enter="onSubmit"
          >
            <template #center>Register</template>
          </DigiQButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
  watchPostEffect,
} from "vue";
import { isEmailAddress } from "@/lib/typeHelpers/stringFunctions/isEmailAddress";
import LFieldError from "@/components/layout/LFieldError";
import { useStore } from "vuex";
import { LIST_ASSETS, REQUEST_DIGIQT_ACCOUNT } from "@/store/operations";
import StatusMessage from "@/components/status/StatusMessage.vue";
import { useStatusMessage } from "@/composables/useStatusMessage";
import TextInput from "@/components/forms/TextInput.vue";
import DropdownSingle from "@/components/forms/DropdownSingle.vue";
import { state } from "@/store/store";
import { digiQRoles } from "@/lib/digiQ/digiQRoles";
import { digiQNetworks } from "@/lib/digiQ/digiQNetworks";
import DigiQButton from "@/components/forms/DigiQButton.vue";
import { PhSpinner } from "@phosphor-icons/vue";

export default {
  name: "RegisterDigiQTAccount",
  components: {
    PhSpinner,
    DigiQButton,
    DropdownSingle,
    TextInput,
    LFieldError,
    StatusMessage,
  },
  props: {
    role: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const form = ref(null);
    const altKey = ref(false);
    const actionError = ref("");
    // isSent is used to show a different message after the user has submitted the form
    const loadingRequest = ref(false);
    const isSubmitted = ref(false);
    const showStudentFields = computed(
      () =>
        props.role?.toLowerCase() === "student" ||
        account.role?.toLowerCase() === "student"
    );
    const showTeacherFields = ref(false);
    const inputError = reactive({});

    const isValidAccount = computed(
      () =>
        account.firstName &&
        account.lastName &&
        account.university &&
        account.email &&
        account.role &&
        (account.role === "Student"
          ? account.programme
          : // account.role === 'teacher' ?
            //     account.coursesTeaching :
            true) &&
        Object.values(inputError).every((msg) => msg === null)
    );

    const canSubmit = computed(() => isValidAccount.value);
    const availableUnis = ref([]);
    const universities = computed(() => {
      let filtered = Object.values(state.assets.University ?? []).filter(
        (item) => item.title !== "Not Listed" ?? item.name !== "Not Listed"
      );
      let temp = filtered.map((item) => item.title ?? item.name);
      temp.sort();
      temp.push("Not Listed");
      return temp;
    });
    store.dispatch(LIST_ASSETS, { dataType: "University" });

    watch(universities, (v) => {
      availableUnis.value = v.filter((item) => !item.startsWith("_"));
    });
    watch(altKey, (v) => {
      if (v) {
        availableUnis.value = universities.value;
      } else {
        availableUnis.value = universities.value.filter(
          (item) => !item.startsWith("_")
        );
      }
    });

    const networkLabelToValue = (value) =>
      digiQNetworks.find((r) => r.label === value).value;

    const account = reactive({
      title: "",
      firstName: null,
      lastName: null,
      university: null,
      // country: null,
      role: props.role || null,
      network: null,
      programme: "",
      // coursesTeaching: '',
      email: null,
    });

    const { setMessage } = useStatusMessage(REQUEST_DIGIQT_ACCOUNT);

    const updateAccount = (property, value, required) => {
      if (value) value = value.trim();
      Object.assign(account, { [property]: value });
      // console.log(93, showStudentFields.value, property, value)
      if (required && !value) {
        inputError[property] = "This field is required";
      } else {
        inputError[property] = null;
      }
    };

    watchPostEffect(() => {
      const { email } = account;

      inputError.email =
        email && !isEmailAddress(email) ? "Invalid email address" : null;

      // todo: do not remove this log, hack for resolving digestion race condition
      console.log(3, email, email && !isEmailAddress(email), inputError.email);
    });

    onMounted(() => {
      window.scroll(0, 0);
      window.addEventListener("keydown", (e) => {
        if (e.key === "Alt") altKey.value = true;
      });
      window.addEventListener("keyup", (e) => {
        if (e.key === "Alt") altKey.value = false;
      });
    });
    onBeforeUnmount(() => {
      window.removeEventListener("keydown", (e) => {
        if (e.key === "Alt") altKey.value = true;
      });
      window.removeEventListener("keyup", (e) => {
        if (e.key === "Alt") altKey.value = false;
      });
    });

    const submitUserAccountRequest = () => {
      if (!isValidAccount.value) return;
      loadingRequest.value = true;
      actionError.value = "";

      const options = {
        ...account,
        // lookup role name
        role: digiQRoles.find((r) => r.label === account.role).value,
        // lookup the repoId for the university
        universityRepoId: Object.values(state.assets?.University).find(
          (i) => i.name === account.university
        ).repoId,
      };
      delete options.university;

      console.log("Requesting user account", options);
      store
        .dispatch(REQUEST_DIGIQT_ACCOUNT, options)
        .then((result) => {
          isSubmitted.value = true;
          return result;
        })
        .catch((err) => {
          console.log("Error requesting user account", err);
          setMessage({ isSuccess: false, body: err.message });
        })
        .finally(() => {
          loadingRequest.value = false;
        });

      // reset form
      Object.keys(options).forEach((key) => delete options[key]);
    };

    const onSubmit = () => submitUserAccountRequest();

    return {
      account,
      actionError,
      altKey,
      canSubmit,
      // countries,
      availableUnis,
      inputError,
      loadingRequest,
      isSubmitted,
      isValidAccount,
      form,
      onSubmit,
      roles: digiQRoles.map((r) => r.label),
      networks: digiQNetworks.map((r) => r.label),
      networkLabelToValue,
      showStudentFields,
      showTeacherFields,
      updateAccount,
    };
  },
};
</script>

<style scoped></style>
