<template>
  <button
    ref="button"
    v-if="buttonStyle && buttonClasses"
    class="flex items-center transition px-5 py-2.5 select-none duration-300 ease-in-out h-fit self-end shadow-md text-sm rounded"
    :class="buttonClasses"
    :style="buttonStyle"
    v-bind="$attrs"
    @click.prevent.stop="onClick"
  >
    <div
      class="w-full flex items-center gap-0.5 justify-center whitespace-nowrap"
    >
      <slot name="left"></slot>
      <slot name="center"></slot>
      <slot name="right"></slot>
    </div>
  </button>
</template>

<script>
import { computed, onMounted, ref } from "vue";

export default {
  name: "DigiQButton",
  emits: ["click"],
  props: {
    isPrimary: {
      default: true,
    },
    isSecondary: {
      default: false,
    },
    isDisabled: {
      default: false,
    },
    isSmall: {
      default: false,
    },
  },
  setup(props, { emit }) {
    const onClick = (e) => emit("click", e);
    const button = ref(null);

    const buttonStyle = computed(() => {
      const style = {};
      if (button.value) {
        const cStyle = getComputedStyle(button.value);
        if (cStyle.padding === "0px") {
          style.padding = props.isSmall ? "0.6rem 1rem" : "0.75rem 2rem";
        }
      }
      return style;
    });

    const buttonClasses = computed(() => {
      const classes = [];

      if (props.isPrimary && !props.isSecondary) {
        classes.push(
          "bg-gradient-to-r from-digiq-orange to-digiq-violet text-white outline outline-1 outline-digiq-orange  "
        );
        if (!props.isDisabled) {
          classes.push("");
        }
      } else if (props.isSecondary) {
        classes.push(
          "bg-gray-200 hover:bg-gray-300 text-gray-700 shadow-sm outline outline-gray-300 outline-1 "
        );
      }

      if (props.isSmall) {
        classes.push("shadow-sm");
      } else {
        classes.push("shadow");
      }

      if (props.isDisabled) {
        classes.push("cursor-not-allowed opacity-50");
      } else {
        classes.push(
          "cursor-pointer hover:from-digiq-violet hover:shadow-lg hover:to-digiq-orange"
        );
      }

      return classes;
    });

    onMounted(() => {
      // Any required logic after mounting
    });

    return {
      button,
      buttonClasses,
      buttonStyle,
      onClick,
    };
  },
};
</script>
