<template>
  <div
    v-for="n in count"
    :key="n"
    class="credential-skeleton py-1 px-2 animate-pulse"
  >
    <div class="flex flex-row gap-4 items-center">
      <!-- Skeleton for Credential Number -->
      <div
        class="bg-gray-300 flex justify-center w-7 h-7 aspect-square transform rotate-45"
      ></div>

      <!-- Skeleton for Credential Text -->
      <div class="bg-gray-300 w-48 h-5 rounded"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonCredential",
  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style scoped></style>
