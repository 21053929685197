<script>
import LSection from "@/components/layout/LSection.vue";
import LView from "@/components/layout/LView.vue";
import {computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import {PhArrowRight} from "@phosphor-icons/vue";

export default {
  name: "Networks",
  components: {
    PhArrowRight,
    LSection,
    LView,
  },
  setup() {
    const networkIds = ['AQISS', 'AQT', 'EFEQT', 'NQC']

    const route = useRoute()
    const router = useRouter()

    const selectedIndex = computed(() => {
      let index = networkIds.indexOf((route.query?.network ?? 'AQISS').toUpperCase())

      if (index === -1) index = 0

      return index
    })
    const onSelect = (index) => router.push({
      query: {
        network: networkIds[index]},
        maintainScroll: true,
    })

    return {
      selectedIndex,
      onSelect,
    }
  }

}
</script>

<template>
  <div class="networks">
    <LView class="mt-20 text-xl font-normal text-gray-600">
      <template #body>
        <LSection class="mt-20 mb-10">
          <template #body>

            <h1 class="mb-20 font-header">DigiQ Networks: A European Web of Quantum Technology Training</h1>
            <p>
              Welcome to DigiQ Networks, the backbone of the DigiQ program. DigiQ Networks offer a unique opportunity to reinforce your studies with hands-on experience in quantum technology, develop a professional network that will last far beyond your study program, and maintain consistent contact with top experts in the field.
            </p>
            <p>
              Each DigiQ Network has its own theme and curates a set of events unique to it. You may be visiting a quantum computing lab, attending a career symposium, or discussing the mysteries of quantum physics in a pub—anywhere across Europe! Events are fully funded for travel for all DigiQ students.
            </p>

<!--            <div class="flex items-center !my-6 pt-8 text-black text-2xl tracking-tight font-black justify-center ">-->
<!--               <span class="bg-amber-300 px-2 rounded-xl" >Network application 2024/2025 is now open</span> <PhArrowRight :size="24" class="mx-2 " /> <button></button><a class="shadow px-2 rounded-xl"-->
<!--                href="https://docs.google.com/forms/d/1rF5srscrLMwf4AHA8elN9STTNE0nFJW1BAckPY7C1CA/viewform?edit_requested=true">Apply for Networks Here</a>-->
<!--            </div>-->

          </template>
        </LSection>
        <LSection>
          <template #body>

            <div class="lResourceCards">
              <div class="flex items-center text-center pl-10 font-bold">Click the network for more information</div>
              <div class="flex-col ">
                <div class="flex-none ">
                  <div class="cards p-4 flex gap-4 font-bold tracking-tight">
                    <div
                        class="bg-representation/10 cursor-pointer"
                        @click="() => onSelect(0)"
                    >
                      <div class="acronym text-2xl">AQISS</div>
                      <div class="name font-normal">Applied Quantum Information Science Student Network</div>
                    </div>
                    <div
                        class="bg-diversity/10 cursor-pointer"
                        @click="() => onSelect(1)"
                    >
                      <div class="acronym font-2xl">AQT</div>
                      <div class="name font-normal">Advocates for Quantum Technology</div>
                    </div>
                    <div
                        class="bg-innovation/10 cursor-pointer"
                        @click="() => onSelect(2)"
                    >
                      <div class="acronym font-2xl">EFEQT</div>
                      <div class="name font-normal">Empowering the Future Experts in Quantum Science and Technology for
                        Europe
                      </div>
                    </div>
                    <div
                        class="bg-communication/10 cursor-pointer"
                        @click="() => onSelect(3)"
                    >
                      <div class="acronym font-2xl">NQC</div>
                      <div class="name font-normal">Network for Quantum Computing</div>
                    </div>
                  </div>
                </div>
                <div class="flex p-4 gap-4 flex-shrink-0 w-fit">

                  <div class="details">

                    <div
                        v-if="selectedIndex === 0"
                        class="page AQISS"
                    >
                      <h2 class="font-header">AQISS – Applied Quantum Information Science Student Network</h2>
                      <p>
                        The AQISS network welcomes all students interested in applications of quantum information
                        science. The goal of this network is to provide unique learning and project opportunities in
                        areas of quantum communication, quantum algorithms and hardware development.
                      </p>
                      <p>
                        Join to have
                        access facilities and expertise across A QISS members and affiliated partners, and to work on
                        relevant use-cases of quantum technologies.
                      </p>
                      <h3>Events</h3>
                      <ul>
                        <li>Regular meetups</li>
                        <li>Summer-/Winter schools</li>
                        <li>Hackathons</li>
                      </ul>
                      <h3>Organisers and Coordinators</h3>
                      <p>
                        Aurél Gábris, gabris.aurel@fjfi.cvut.cz
                      </p>
                      <p>
                        Jonatan Bohr Brask,  jobb@fysik.dtu.dk
                      </p>
                      <img src="../../../assets/images/AQISS.png" alt="">
                      <h3>Core Partners</h3>
                      <div class="logos">
                        <img src="../../../assets/images/CTU.png" alt="">
                        <img src="../../../assets/images/DTU.png" alt="">
                        <img src="../../../assets/images/UPS.png" alt="">
                        <img src="../../../assets/images/UH.png" alt="">
                        <img src="../../../assets/images/UB.jpg" alt="">
                      </div>
                      <div class="affiliated">
                        <h3>Affiliated Partners</h3>
                        <div class="logos">
                          <img src="../../../assets/images/AlU.png" alt="">
                          <img src="../../../assets/images/I.png" alt="">
                          <img src="../../../assets/images/IDO.jpg" alt="">
                        </div>
                      </div>
                    </div>

                    <div
                        v-if="selectedIndex === 1"
                        class="page AQT"
                    >
                      <h2 class="font-header">AQT – Advocates for Quantum Technology</h2>
                      <p>
                        In 2025, UNESCO will dedicate an International Year of Quantum Science and Technology. This
                        field is growing at an incredible rate, and it will fall to us as Quantum Technologists to
                        ensure that the public and other scientists have an understanding of and fascination for QT.
                      </p>
                      <p>
                        That is where you come in! As an advocate for Quantum Technology, you will be involved in major
                        international QT events such as World Quantum Day, Italian Quantum Weeks, Pint of Science, and
                        many more, spreading the word of QT.
                      </p>
                      <p>
                        Staff in the AQT network include top researchers in fields of Quantum Simulation &
                        Communication, as well as spearheading Europe’s education and outreach initiatives such as
                        Quantum Technologies Education for Everyone (QuTE4E).
                      </p>
                      <h3>Events</h3>
                      <p>
                        Events in this network will orient around major international activities: Italian Quantum Weeks
                        (taking place across 17 cities in Italy over 6 weeks), and World Quantum Day (annually over 400
                        events).
                      </p>
                      <p>
                        Science Melting Pot will organise several events in Aarhus, Denmark, such as “Pitcher
                        of Quantum”, a several day Quantum Festival, and “Pint of Science”, a global science festival
                        taking place in local bars and cafes.
                      </p>
                      <p>
                        There are many more opportunities to be involved in
                        events, both attending and organising, and this network has travel funding available for up to
                        two events per person per year!
                      </p>
                      <h3>Organisers and Coordinators</h3>
                      <p>
                        Simon Goorney, Aarhus University (simon.goorney@mgmt.au.dk)
                      </p>
                      <p>
                        Co-coordinator, Yasser Omar, Instituto Superior Técnico (yasser.omar@tecnico.ulisboa.pt)
                      </p>
                      <img src="../../../assets/images/img1.png" alt="">
                      <h3>Core Partners</h3>
                      <div class="logos">
                        <img src="../../../assets/images/marchio_unipi_pant541_288.gif" alt="">
                        <img src="../../../assets/images/Aarhus_University_logo.svg.png" alt="">
                        <img src="../../../assets/images/SMP_transparent.6cb95fdc.png" alt="">
                        <img src="../../../assets/images/IST_A_RGB_POS.png" alt="">
                        <img src="../../../assets/images/PQI.png" alt="">
                        <img src="../../../assets/images/ICFO-01.e530bc65.png" alt="">
                        <img src="../../../assets/images/Deloitte_black.png" alt="">
                      </div>
                      <div class="affiliated">

                        <h3>Affiliated Partners</h3>
                        <div class="logos">
                          <img src="../../../assets/images/iqws.png" alt="">
                          <img src="../../../assets/images/logo_WQD_black.png" alt="">
                        </div>
                      </div>
                    </div>

                    <div
                        v-if="selectedIndex === 2"
                        class="page EFEQT"
                    >
                      <h2 class="font-header">EFEQT – Empowering the Future Experts in Quantum Science and Technology for Europe</h2>
                      <p>
                        EFEQT is a one year complementary training programme for students performing their Master
                        studies at a European University. It provides accredited training modules and networking
                        opportunities within the DigiQ Master program.
                      </p>
                      <p>
                        Enhance your Masters degree with international
                        experience leading towards a PhD and be part of a pan-European community preparing you for
                        leadership roles in the emerging quantum workforce.
                      </p>
                      <h3>Events</h3>
                      <ul>
                        <li>Quantum Ideas Factory (science hackathon) in Paris in October 2023</li>
                        <li>Monthly online meetups with top experts from industry and academia</li>
                      </ul>
                      <h3>Organisers and Coordinators</h3>
                      <p>
                        Coordinator: Shannon Whitlock (Strasbourg), whitlock@unistra.fr
                      </p>
                      <p>
                        Co-coordinator: Martin Gärttner (University of Jena), martin.gaerttner@uni-jena.de
                      </p>
                      <p>
                        Meetups: Rianne Lous (Eindhoven), r.s.lous@tue.nl
                      </p>
                      <h3>Core Partners</h3>
                      <div class="logos">
                        <img src="../../../assets/images/UniH.png" alt="">
                        <img src="../../../assets/images/US.png" alt="">
                        <img src="../../../assets/images/SU.png" alt="">
                        <img src="../../../assets/images/ENS.png" alt="">
                        <img src="../../../assets/images/PSL.png" alt="">
                      </div>

                    </div>

                    <div
                        v-if="selectedIndex === 3"
                        class="page QNC"
                    >
                      <h2 class="font-header">NQC – Network for Quantum Computing</h2>
                      <p>
                        NQC is a good fit for students interested in quantum computing and quantum information theory.
                        Topics covered in NQC include:
                      </p>
                      <ul>
                        <li>
                          Theoretical & Experimental Quantum Computing
                          <ul>
                            <li>Quantum algorithms</li>
                            <li>Software applications</li>
                            <li>Hardware architectures</li>
                            <li>Methods in Quantum Information Theory</li>
                          </ul>
                        </li>
                        <li>Industrial applications & local Start-Ups</li>
                        <li>Entrepreneurship</li>
                      </ul>
                      <h3>Events</h3>
                      <ul>
                        <li>Scientific seminars & workshops</li>
                        <li>Outreach projects</li>
                        <li>Internship matchmaking</li>
                        <li>Platforms for Quantum Technologies School (Aachen, March)</li>
                        <li>QMATH Masterclass (Copenhagen, August)</li>
                      </ul>
                      <h3>Organisers and Coordinators</h3>
                      <p>
                        Coordinator: Albert H. Werner, CPH (werner@math.ku.dk)
                        Co-coordinator: Lisa Otten, RWTH (l.otten@physik.rwth-aachen.de)
                      </p>
                      <h3>Core Partners</h3>
                      <div class="logos">
                        <img src="../../../assets/images/KU.png" alt="">
                        <img src="../../../assets/images/UAB.png" alt="">
                        <img src="../../../assets/images/RWTH.png" alt="">
                        <img src="../../../assets/images/LMU.png" alt="">
                        <img src="../../../assets/images/Quarks.png" alt="">
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          </template>
        </LSection>
      </template>
    </LView>

  </div>
</template>

<style scoped>
h1 {
  @apply
  text-5xl font-bold text-gray-800
  my-6
}

h2 {
  @apply
  text-3xl font-bold text-gray-800
  mb-4
}

h3 {
  @apply
  text-2xl font-bold text-gray-800
  mt-8
  mb-2.5
}

p {
  @apply
  my-4
}

.cards > * {
  @apply
  h-fit
  shadow
  p-6
}

.logos {
  @apply
  mt-12
  flex flex-wrap items-center gap-8
}

.affiliated {
  @apply
  bg-gray-100 mt-12 p-10
}

.logos img {
  max-height: 200px;
  max-width: 300px;
}

.textblock {
  @apply
  h8:columns-2
  k12:columns-3
  mb-20
}

ul {
  @apply
  list-disc
  ml-8
}

.textblock div {
  @apply
  mb-4
}

em {
  @apply
  font-black
  tracking-tight
  not-italic
}

a {
  @apply
  text-diversity/80
  hover:text-diversity
  transition-colors
  duration-200
}
</style>