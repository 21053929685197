import Students from "../../components/views/digiq/Students.vue";
import Networks from "../../components/views/digiq/Networks.vue";
import Partners from "../../components/views/digiq/Partners.vue";
import DigiQTrackLandingView from "@/components/views/track/DigiQTrackLandingView.vue";
import DigiQHome from "@/components/views/digiq/DigiQHome.vue";
import DigiQLandingView from "@/components/views/digiq/DigiQLandingView.vue";
import Mobility from "@/components/views/digiq/Mobility.vue";
export const appRoutes = [
  {
    path: "/",
    name: "home",
    component: DigiQLandingView,
    children: [
      {
        path: "",
        name: "home",
        component: DigiQHome,
      },
      {
        path: "partners",
        name: "partners",
        component: Partners,
      },
      {
        path: "students",
        name: "students",
        component: Students,
      },
      {
        path: "networks",
        name: "networks",
        component: Networks,
      },
      {
        path: "mobility",
        name: "mobility",
        component: Mobility,
      },
      {
        path: "contact",
        name: "contact",
        component: () => import("../../components/views/digiq/Contact.vue"),
      },
    ],
  },
  {
    path: "/track",
    name: "track",
    component: DigiQTrackLandingView,
    children: [
      {
        path: "",
        name: "trackHome",
        component: () => import("../../components/views/track/TrackHome.vue"),
      },
      {
        path: "info",
        name: "trackInfo",
        component: () => import("../../components/views/track/TrackInfo.vue"),
      },
      {
        /*
                    header + create
                    filter: Courses | Internships | Projects
                    card grid > resource
                 */
        path: "dashboard",
        name: "dashboard",
        meta: {
          requireLogin: true,
        },
        component: () =>
          import("../../components/views/track/TrackDashboard.vue"),
      },
      {
        /*
                    header + create
                    filter: Courses | Internships | Projects
                    card grid > resource
                 */
        path: "certificates",
        name: "certificates",
        meta: {
          requireLogin: true,
        },
        component: () =>
          import("../../components/views/track/certificates/Certificate.vue"),
      },
      {
        /*
                    header + create
                    filter: Courses | Internships | Projects
                    card grid > resource
                 */
        path: "certificates/:repoId/:courseId",
        name: "certificate",
        props: true,
        meta: {
          requireLogin: true,
        },
        component: () =>
          import(
            "../../components/views/track/certificates/CertificateView.vue"
          ),
      },
      {
        path: "database",
        name: "trackDatabase",
        component: () =>
          import("../../components/views/track/TrackDatabase.vue"),
      },
      {
        path: "courses/:repoId/advert",
        name: "courseAdvert",
        props: true,
        component: () =>
          import("../../components/views/track/course/TrackCourseAdvert.vue"),
      },
      {
        path: "courses/:repoId/manage",
        name: "courseManagement",
        props: true,
        meta: {
          requireLogin: true,
        },
        component: () =>
          import(
            "../../components/views/track/course/TrackCourseManagement.vue"
          ),
      },
      {
        path: "internships/:repoId/advert",
        name: "internshipAdvert",
        props: true,
        component: () =>
          import(
            "../../components/views/track/internship/TrackInternshipAdvert.vue"
          ),
      },
      {
        path: "internships/:repoId/manage",
        name: "internshipManagement",
        props: true,
        meta: {
          requireLogin: true,
        },
        component: () =>
          import(
            "../../components/views/track/internship/TrackInternshipManagement.vue"
          ),
      },
      {
        path: "networks/:repoId/advert",
        name: "networkCommitmentAdvert",
        props: true,
        component: () =>
          import(
            "../../components/views/track/network/TrackNetworkCommitmentAdvert.vue"
          ),
      },
      {
        path: "networks/:repoId/manage",
        name: "networkCommitmentManagement",
        props: true,
        meta: {
          requireLogin: true,
        },
        component: () =>
          import(
            "../../components/views/track/network/TrackNetworkCommitmentManagement.vue"
          ),
      },
    ],
  },
];
