export const LIST_ASSETS = "LIST_ASSETS";
export const LIST_PRIVATE_ASSETS = "LIST_PRIVATE_ASSETS";
export const GET_CLIENT_ID = "GET_CLIENT_ID";
export const GET_ASSET = "GET_ASSET";
export const GET_COMMITMENT = "GET_COMMITMENT"; // does not know permissionHost
export const CREATE_ASSET = "CREATE_ASSET";
export const PUT_ASSET = "PUT_ASSET";
export const PUT_PRIVATE_ASSET = "PUT_PRIVATE_ASSET";
export const PUBLISH_ASSET = "PUBLISH_ASSET";
export const RETRACT_ASSET = "RETRACT_ASSET";
export const PUT_ASSET_ATTRIBUTE = "PUT_ASSET_ATTRIBUTE";
export const DELETE_ASSET = "DELETE_ASSET";

export const LIST_USERS = "LIST_USERS";
export const LIST_UNIVERSITY_PEOPLE = "LIST_UNIVERSITY_PEOPLE";
export const LIST_COMMITMENT_PEOPLE = "LIST_COMMITMENT_PEOPLE";
export const LIST_ASSET_MEMBERS = "LIST_ASSET_USERS";
export const DEMOTE_ASSET_USER = "DEMOTE_ASSET_USER";
export const PROMOTE_ASSET_USER = "PROMOTE_ASSET_USER";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const EXPEL_ASSET_USER = "EXPEL_ASSET_USER";
export const GET_USER = "GET_USER";
export const REQUEST_DIGIQT_ACCOUNT = "REQUEST_DIGIQT_ACCOUNT";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const RESUME_SESSION = "RESUME_SESSION";
export const REQUEST_FORGET_USER = "REQUEST_FORGET_USER";
export const REQUEST_ROLE = "REQUEST_ROLE";
export const GRANT_ROLE = "GRANT_ROLE";
export const DENY_ROLE = "DENY_ROLE";
export const REVOKE_ROLE = "REVOKE_ROLE";
export const GET_USER_AWARDS = "GET_USER_AWARDS";
export const GRANT_USER_AWARD = "GRANT_USER_AWARD";
export const REVOKE_USER_AWARD = "REVOKE_USER_AWARD";

export const LIST_APP_USERS = "LIST_APP_USERS";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const REQUEST_CHANGE_EMAIL = "REQUEST_CHANGE_EMAIL";

// token confirmation
export const VERIFY_ACTIVATE_ACCOUNT_TOKEN = "VERIFY_ACTIVATE_ACCOUNT_TOKEN";
export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";
export const CONFIRM_RESET_PASSPHRASE = "CONFIRM_RESET_PASSPHRASE";
export const CONFIRM_CHANGE_EMAIL = "CONFIRM_CHANGE_EMAIL";
export const ACTIVATE_NEW_EMAIL = "ACTIVATE_NEW_EMAIL";
export const CONFIRM_DOWNLOAD_MY_DATA = "CONFIRM_DOWNLOAD_MY_DATA";
export const CONFIRM_FORGET_ABOUT_ME = "CONFIRM_FORGET_ABOUT_ME";

// DIGIQ TRACK
export const CREATE_COURSE = "CREATE_COURSE";
export const CREATE_INTERNSHIP = "CREATE_INTERNSHIP";
export const CREATE_NETWORK_COMMITMENT = "CREATE_NETWORK_COMMITMENT";
export const SELECT_MODULE = "SELECT_MODULE";
export const CREATE_MILESTONE = "CREATE_MILESTONE";
export const CREATE_ECTS_MILESTONE = "CREATE_ECTS_MILESTONE";
export const PUBLISH_COURSE = "PUBLISH_COURSE";
export const PUBLISH_INTERNSHIP = "PUBLISH_INTERNSHIP";
export const PUBLISH_NETWORK_COMMITMENT = "PUBLISH_NETWORK_COMMITMENT";
export const SET_COURSE_ACCREDITED_AT = "SET_COURSE_ACCREDITED_AT";
export const RESET_COURSE_ACCREDITED_AT = "RESET_COURSE_ACCREDITED_AT";
export const LIST_DRAFTS = "LIST_DRAFTS";
export const DELETE_UNENROLLED_UNAWARDED_MILESTONE =
  "DELETE_UNENROLLED_UNAWARDED_MILESTONE";
export const DELETE_UNENROLLED_UNAWARDED_COURSE =
  "DELETE_UNENROLLED_UNAWARDED_COURSE";
export const DELETE_UNENROLLED_UNAWARDED_INTERNSHIP =
  "DELETE_UNENROLLED_UNAWARDED_INTERNSHIP";
export const DELETE_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT =
  "DELETE_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT";
export const RETRACT_UNENROLLED_UNAWARDED_COURSE =
  "RETRACT_UNENROLLED_UNAWARDED_COURSE";
export const RETRACT_UNENROLLED_UNAWARDED_INTERNSHIP =
  "RETRACT_UNENROLLED_UNAWARDED_INTERNSHIP";
export const RETRACT_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT =
  "RETRACT_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT";
export const APPLY_FOR_COURSE = "APPLY_FOR_COURSE";
export const APPLY_FOR_INTERNSHIP = "APPLY_FOR_INTERNSHIP";
export const APPLY_FOR_NETWORK_COMMITMENT = "APPLY_FOR_NETWORK_COMMITMENT";
export const TRIGGER_MILESTONE = "TRIGGER _MILESTONE";
export const DELIST_COMMITMENT = "DELIST_COMMITMENT";
export const LIST_HOSTED_COMMITMENTS = "LIST_HOSTED_COMMITMENTS";
export const LIST_COMMITMENTS = "LIST_COMMITMENTS";
export const LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS_AWARDS =
  "LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS_AWARDS";
export const LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS =
  "LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS";
export const LIST_APPLICANTS = "LIST_APPLICANTS";
export const ENROLL_APPLICANTS = "ENROLL_APPLICANTS";
export const REJECT_STUDENT = "REJECT_STUDENT";
export const EXPEL_STUDENT = "EXPEL_STUDENT";
export const VERIFY_ROLE_REQUEST = "VERIFY_ROLE_REQUEST";
export const VERIFY_APP_ROLE_REQUEST = "VERIFY_APP_ROLE_REQUEST";
export const CLAIM_TEACHER_ROLE = "CLAIM_TEACHER_ROLE";
export const GET_ACCOUNT_ROLES = "GET_ACCOUNT_ROLES";
export const LIST_UNIVERSITY_REPRESENTATIVES =
  "LIST_UNIVERSITY_REPRESENTATIVES";

// INTERNAL MUTATIONS
export const _PUT_STATUS = "_PUT_STATUS";
export const _RESET_ASSETS = "_RESET_ASSETS";
