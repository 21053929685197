import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import "./assets/css/leaflet.css";
import "vue-select/dist/vue-select.css";
import "./assets/css/tailwind.css";
import vSelect from "vue-select";
import "./index.css";
import dayjs from "./plugins/dayjs.js";
import axios from "./plugins/axios";
import "vue3-toastify/dist/index.css";

createApp(App)
  .use(store)
  .use(router)
  .provide("AXIOS", axios)
  .provide("DAYJS", dayjs)
  .component("v-select", vSelect)
  .mount("#app");
