<script>
import Credential from "@/components/track/CredentialWallet/Credential.vue";
import SkeletonCredential from "@/components/track/CredentialWallet/SkeletonCredential.vue";
import { computed } from "vue";

export default {
  name: "Credentials",
  components: {
    Credential,
    SkeletonCredential,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    useColumns: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const credentials = computed(() => [...new Set(props.list)]);
    console.log(props.isLoading);
    return {
      credentials,
    };
  },
};
</script>

<template>
  <div
    class="credentials bg-white shadow-md rounded-md gap-2 p-4"
    :class="{
      'grid grid-cols-1 k14:grid-cols-2': useColumns,
      'flex flex-col': !useColumns,
    }"
  >
    <SkeletonCredential :count="2" v-if="isLoading" />
    <credential v-for="cred in credentials" :key="cred" :credential="cred">
    </credential>
    <p
      v-if="credentials.length === 0 && !isLoading"
      class="text-gray-500 text-sm"
    >
      None
    </p>
  </div>
</template>

<style scoped></style>
