<script>
import { computed, ref } from "vue";
import { useGenericDocument } from "@/composables/useGenericDocument";
import store, { state } from "@/store/store";
import Gravatar from "@/components/gravatar/Gravatar.vue";
import StudentDetailsCardFields from "@/components/person/StudentDetailsCardFields.vue";
import TeacherDetailsCardFields from "@/components/person/TeacherDetailsCardFields.vue";
import DigiQButton from "../../forms/DigiQButton.vue";
import {
  CREATE_COURSE,
  GET_USER_AWARDS,
  GRANT_USER_AWARD,
  REVOKE_USER_AWARD,
} from "@/store/operations";
import { useUserPermissions } from "@/composables/useUserPermissions";

export default {
  // todo: card for each person role
  name: "CourseManagementCard",
  components: {
    Gravatar,
    DigiQButton,
  },
  props: {
    repoId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      required: true,
    },
    isApplicant: {
      default: false,
    },
  },
  setup(props) {
    const { document } = useGenericDocument("_Person", props.repoId);
    const awardList = ref([]);

    const getAwards = () => {
      store
        .dispatch(GET_USER_AWARDS, {
          userRepoId: props.repoId,
        })
        .then((response) => {
          console.log(101, response.data.Award);
          awardList.value = response.data.Award;
        })
        .catch((error) => {
          console.log("getAwards", error);
          awardList.value = [];
        });
    };
    getAwards();

    const name = computed(
      () =>
        document.value.name ||
        (document.value.firstName &&
          document.value.firstName &&
          document.value.firstName + " " + document.value.lastName)
    );

    const isAwarded = computed(() => {
      return awardList.value.some((award) => award.courseId === props.courseId);
    });

    const isStudent = computed(() =>
      document.value.roles?.find((r) => r.endsWith("student"))
    );
    const isTeacher = computed(() =>
      document.value.roles?.find((r) => r.endsWith("teacher"))
    );

    const onGrantAward = () => {
      const options = { userRepoId: props.repoId, courseId: props.courseId };
      store
        .dispatch(GRANT_USER_AWARD, options)
        .then((result) => {
          return result;
        })
        .finally(() => getAwards());
    };

    const onRevokeAward = () => {
      const options = { userRepoId: props.repoId, courseId: props.courseId };
      store
        .dispatch(REVOKE_USER_AWARD, options)
        .then((result) => {
          return result;
        })
        .finally(() => getAwards());
    };

    return {
      document,
      isStudent,
      isTeacher,
      name,
      state,
      onGrantAward,
      onRevokeAward,
      isAwarded,
    };
  },
};
</script>

<template>
  <div class="CourseManagementCard bg-white p-8 rounded-xl drop-shadow-lg">
    <div class="flex items-center gap-8">
      <div
        class="rounded-full bg-gray-300 h-[96px] w-[96px] overflow-clip flex items-center"
      >
        <Gravatar
          v-if="document.email"
          class=""
          :email="document.email"
          :size="96"
        ></Gravatar>
      </div>
      <div class="font-bold text-3xl tracking-tight mb-3">
        {{ document.title }} {{ name }}
      </div>
    </div>
    <div v-if="!isAwarded" class="flex pt-4 justify-around">
      <DigiQButton
        class="!self-auto h-full w-fit border-l-0 outline-0 rounded-full"
        :is-primary="true"
        @click="onGrantAward"
      >
        <template #center>Award Certificate</template>
      </DigiQButton>
    </div>
    <div v-else class="flex pt-4 justify-around">
      <DigiQButton
        class="!self-auto h-full whitespace-pre-line w-fit border-l-0 outline-0 rounded-full"
        :is-primary="true"
        @click="onRevokeAward"
      >
        <template #center>Revoke Certificate</template>
      </DigiQButton>
    </div>
  </div>
</template>

<style scoped></style>
