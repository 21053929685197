<script>
import { computed } from "vue";
import { useGenericDocument } from "@/composables/useGenericDocument";
import { state } from "@/store/store";
import Gravatar from "@/components/gravatar/Gravatar.vue";
import StudentDetailsCardFields from "@/components/person/StudentDetailsCardFields.vue";
import TeacherDetailsCardFields from "@/components/person/TeacherDetailsCardFields.vue";

export default {
  // todo: card for each person role
  name: "PersonDetailsCard",
  components: {
    TeacherDetailsCardFields,
    Gravatar,
    StudentDetailsCardFields,
  },
  props: {
    repoId: {
      type: String,
      required: true,
    },
    isApplicant: {
      default: false,
    },
  },
  setup(props) {
    const { document } = useGenericDocument("_Person", props.repoId);

    const name = computed(
      () =>
        document.value.name ||
        (document.value.firstName &&
          document.value.firstName &&
          document.value.firstName + " " + document.value.lastName)
    );

    const awards = computed(() => {
      return false;
    });

    const isStudent = computed(() =>
      document.value.roles?.find((r) => r.endsWith("student"))
    );
    const isTeacher = computed(() =>
      document.value.roles?.find((r) => r.endsWith("teacher"))
    );
    return {
      awards,
      document,
      isStudent,
      isTeacher,
      name,
      state,
    };
  },
};
</script>

<template>
  <div class="personDetailsCard bg-white p-8 rounded-xl drop-shadow-lg">
    <div class="flex items-center gap-8">
      <div
        class="rounded-full h-[96px] w-[96px] bg-gray-300 overflow-clip flex items-center"
      >
        <Gravatar
          v-if="document.email"
          class=""
          :email="document.email"
          :size="96"
        ></Gravatar>
      </div>
      <div class="font-bold text-3xl tracking-tight mb-3">
        {{ document.title }} {{ name }}
      </div>
    </div>
    <div class="font-thin text-2xl">
      {{ document.programme }}
    </div>
    <section v-if="!document.programme" class="mt-2">
      Not currently enrolled in any programs ...
    </section>
    <p class="mb-4">
      {{ document.university }}
    </p>
    <section v-if="awards">
      <div class="text-xl font-semibold tracking-tight mb-3">Awards ...</div>
    </section>
    <section v-else>
      <div class="">No awards yet ...</div>
    </section>
    <section v-if="isTeacher">
      <TeacherDetailsCardFields :repo-id="repoId"></TeacherDetailsCardFields>
    </section>
    <section v-if="isStudent">
      <StudentDetailsCardFields :repo-id="repoId"></StudentDetailsCardFields>
    </section>
  </div>
</template>

<style scoped></style>
