<template>
  <div
    class="lModal overflow-x-hidden h-full min-h-screen flex-col flex items-center overscroll-none backdrop-blur-sm"
    :class="{ 'pointer-events-none': !clickable }"
    id="staticBackdropLabel"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    @click="onClose"
    v-bind="$attrs"
  >
    <div
      class="modal-dialog mx-2 my-auto relative m-auto pointer-events-none flex items-center justify-center"
    >
      <div
        class="modal-content my-4 border-none outline-none flex justify-center flex-col pointer-events-auto text-current"
        @click.stop
      >
        <slot name="body">modal</slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LModal",
  emits: ["close"],
  props: {
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const onClose = () => emit("close");
    return {
      onClose,
    };
  },
};
</script>

<style scoped></style>
