<template>
  <div class="app flex flex-col min-h-screen">
    <lView class="app relative text-slate-900">
      <template #body>
        <LModal
          v-show="modalComponent"
          class="fixed bg-black/30 fade-in w-screen min-h-screen"
          style="z-index: 14000"
          @close="onModalClose"
        >
          <template #body>
            <component
              v-if="modalComponent"
              :is="modalComponent"
              v-bind="modalParams"
              @close="onModalClose"
              @done="onModalClose"
            ></component>
          </template>
        </LModal>
        <div class="relative w-full flex flex-col items-stretch">
          <router-view class="h-full"></router-view>
        </div>
      </template>
    </lView>
    <Footer></Footer>
  </div>
</template>

<script>
import LView from "@/components/layout/LView";
import { computed, ref, shallowRef, watch } from "vue";
import LModal from "@/components/layout/LModal";
import { _PUT_STATUS, RESUME_SESSION } from "@/store/operations";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useUserPermissions } from "@/composables/useUserPermissions";
import Login from "@/components/authentication/Login";
import RegisterUserAccount from "@/components/authentication/RegisterDigiQTAccount.vue";
import AssetStatus from "@/components/asset/AssetStatus.vue";
import { state } from "@/store/store";
import { withoutPostfix } from "@/lib/prefixPostfix";
import Footer from "@/components/layout/Footer.vue";
import PersonDetailsCard from "@/components/person/PersonDetailsCard.vue";
import "vue3-toastify/dist/index.css";
import courseManagementCard from "./components/track/Course/CourseManagementCard.vue";

const modalComponentNames = {
  person: PersonDetailsCard,
  login: Login,
  "sign up": RegisterUserAccount,
  manageCourse: courseManagementCard,
};

const appAdminModalComponentNames = {};

const resolveModalComponent = (modal, isAdmin) => {
  let name = modalComponentNames[modal];
  if (!name && isAdmin) name = appAdminModalComponentNames[modal];
  name = name ?? "";
  return name;
};

export default {
  name: "App",
  components: {
    AssetStatus,
    LModal,
    Login,
    LView,
    RegisterUserAccount,
    Footer,
    PersonDetailsCard,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const my = useUserPermissions();
    const modalComponent = shallowRef("");
    const modalParams = ref({});
    const clientId = computed(() => state.clientId);

    const actionErrors = computed(() =>
      Object.entries(state.status)
        // eslint-disable-next-line no-unused-vars
        .filter(([key, value]) => value?.isError === true)
        .map(([name, value]) => ({
          actionName: name,
          name: withoutPostfix(withoutPostfix(name, "Error"), " Error"),
          message: value.body,
        }))
    );
    const permissionItems = {
      readAsset: "Read Asset",
      maintainAsset: "Maintain Asset",
      developAsset: "Create, Edit, Delete Asset",
      manageAsset: "Manage Asset",
    };
    store.commit(RESUME_SESSION);

    watch(
      router.currentRoute,
      (route) => {
        const isAdmin = my.user.value?.__isAppAdmin;
        const { query } = route;
        const { modal } = query;

        modalParams.value = { ...query };
        delete modalParams.value.modal;

        modalComponent.value = resolveModalComponent(modal, isAdmin);
      },
      { immediate: true }
    );

    const onModalClose = () => {
      router.push({ query: {} });
    };
    const onAcknowledgeActionError = (action) => {
      const { actionName } = action;
      console.log(93, actionName);
      store.commit(_PUT_STATUS, {
        event: actionName,
        result: {
          _status: { isError: false, body: null },
        },
      });
    };

    return {
      actionErrors,
      clientId,
      modalComponent,
      modalParams,
      onAcknowledgeActionError,
      onModalClose,
      permissionItems,
      state,
    };
  },
};
</script>
<style lang="css">
.button.main,
.button.primary,
button.main,
button.primary {
  color: white;
  background-image: linear-gradient(
    to bottom right,
    #2c7ce5,
    #002395
  ) !important;
}

.button.main:hover,
.button.primary:hover,
button.main:hover,
button.primary:hover {
  background-image: linear-gradient(
    to top left,
    #002395,
    #002395,
    #2c7ce5
  ) !important;
}

.gradientLine {
  background-image: linear-gradient(to bottom right, #002395, #002395, #2c7ce5);
}

.contactDetails h1 {
  @apply text-5xl font-bold text-gray-800
  my-6;
}

.contactDetails h2 {
  @apply text-3xl font-bold text-gray-800
  mb-4;
}

.contactDetails h3 {
  @apply text-2xl font-bold text-gray-800

  mb-1.5;
}

.contactDetails .cards > * {
  @apply h-40
  shadow
  p-6;
}

.contactDetails .logos {
  @apply mt-12
  flex flex-wrap items-center gap-8;
}

.contactDetails .affiliated {
  @apply bg-gray-100 mt-12 p-10;
}

.contactDetails .logos img {
  max-height: 200px;
  max-width: 300px;
}

.contactDetails .textblock {
  @apply h8:columns-2
  k12:columns-3
  mb-20;
}

.contactDetails ul {
  @apply list-disc
  ml-8;
}

.contactDetails .textblock div {
  @apply mb-4;
}

.contactDetails em {
  @apply font-black
  tracking-tight
  not-italic;
}

.contactDetails a {
  @apply text-diversity/80
  hover:text-diversity
  transition-colors
  duration-200;
}
</style>
